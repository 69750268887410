export const RESTAURANTS = [
    {
      // Сомелье вместо кальянщика
      name: 'sommelier',
      data: [
        'goryachii-ceh-10548',
        'gostinnii-dvor-8782'
      ]
    },
    {
      // Бариста вместо кальянщика
      name: 'barista',
      data: [
        'travelers-coffee-10904',
        'travelers-coffee-10906',
        'travelers-coffee-10903',
        'travelers-coffee-10905'
      ]
    },
    {
     // Чаевые Бармену вместо кальянщика
      name: 'fromHookahToBarmen',
      data: [
        'budu-pozdno-7629',
        'v-stekle-11530'
      ]
    },
    {
      // Чаевые Бармену вместо семьи
      name: 'barmen',
      data: [
        'testrkeeper',
        'redwood-volgograd-11474',
        'gaudi-bar-10769'
      ]
    }
  ]

