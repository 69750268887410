import { GetterTree, MutationTree } from 'vuex'

interface IState {
  layout: string
}

type RootState = ReturnType<typeof state>

const state = (): IState => ({
  layout: 'default-layout',
})

const mutations: MutationTree<RootState> = {
  SET_LAYOUT(state, payload) {
    state.layout = payload
  },
}

const getters: GetterTree<RootState, RootState> = {
  LAYOUT(state) {
    return state.layout
  },
}

export default {
  mutations,
  getters,
}
