import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  menuProfile: number | null
}

const state = (): IState => ({
  menuProfile: null,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_MENU_PROFILE(state, payload) {
    state.menuProfile = payload
  },
}
const actions: ActionTree<RootState, RootState> = {
  CHAANGE_ACTIVE_PROFILE_MENU({ commit }, payload) {
    commit('SET_MENU_PROFILE', payload)
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_MENU_PROFILE(state) {
    return state.menuProfile
  },
}
export default {
  namespaced: true,
  mutations,
  actions,
  getters,
}

