import axios from 'axios'
import { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  error_feedback: boolean
  success: boolean
}

interface IFeedbackResponse {
  success: boolean
  result: {
    name_organization: string
  }
}

interface IDynamic {
  [key: string]: string
}

const state = (): IState => ({
  error_feedback: false,
  success: false,
})

type RootState = ReturnType<typeof state>


const mutations: MutationTree<RootState> = {
  SET_ERROR(state, payload) {
    state.error_feedback = payload
  },
  CLEAR_FEEDBACK_ERROR(state) {
    state.error_feedback = false
  },
  SET_SUCCESS(state, payload) {
    state.success = payload
  },
}

const actions: ActionTree<RootState, RootState> = {
  async FEEDBACK({ commit }, payload: IDynamic) {
    commit('CLEAR_FEEDBACK_ERROR')
    try {
      const req: IDynamic = {}
      const arrayOfPayload: string[] = Object.keys(payload)
      arrayOfPayload.forEach((i) => {
        req[i] = payload[i]
      })

      const { data }: AxiosResponse<IFeedbackResponse> = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/services/back-call`,
        req
      )
      if (data.success) {
        commit('SET_SUCCESS', true)
        setTimeout(() => {
          commit('SET_SUCCESS', false)
        }, 2000)
      }
    } catch (e) {
      console.log(e)
    }
  },
  CLOSE_ACCESS({ commit }) {
    commit('SET_SUCCESS', false)
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_FEEDBACK_ERROR(state) {
    return state.error_feedback
  },
  GET_FEEDBACK_SUCCESS(state) {
    return state.success
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
