import axios, { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IService {
  id: string
  name: string
}

interface IServiceResponse {
  success: boolean
  result: IService
}

interface IState {
  services: IService | null
}

const state = (): IState => ({
  services: null,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_SERVICES(state, service) {
    state.services = service
  },
}

const actions: ActionTree<RootState, RootState> = {
  async GET_INTEGRATION_SERVIVES({ commit }, payload) {
    try {
      const { data }: AxiosResponse<IServiceResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/services/connection-platform`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (data.success) {
        commit('SET_SERVICES', data.result)
      }
    } catch (e) {
      console.log(e)
    }
  },
  async INTEGRATION({ commit }, payload) {
    const require = {
      platform: payload.service,
    }
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/services/connection-platform`,
        require,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_SERVICES', { name: payload.service })
    } catch (e) {
      console.log(e)
    }
  },
}

const getters: GetterTree<RootState, RootState> = {
  SERVICES(state) {
    return state.services
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
