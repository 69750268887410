export const getEmployeeName = (role: string): string => {
  switch(role) {
    case 'МОС':
      return 'MOS'
    case 'Тимлид ОС':
      return 'OS'
    case 'РОП':
      return 'ROP'
    case 'МОП':
      return 'MOP'
    default:
      return role
  }
}
