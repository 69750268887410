import ProfileLayout from '@/views/layouts/profileLayout.vue'
import SignLayout from '@/views/layouts/signLayout.vue'
import DisableAutocomplete from '@aacassandra/vue-disable-autocomplete'
import axios from 'axios'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Plugins
import vClickOutside from 'click-outside-vue3'
import Maska from 'maska'
import VueScrollTo from 'vue-scrollto'
import VueCookies from 'vue3-cookies'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

let bugsnagVue
if (process.env.VUE_APP_BUGSNAG_MODE === 'production') {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    releaseStage: process.env.VUE_APP_BUGSNAG_MODE,
    enabledReleaseStages: ['production'],
    plugins: [new BugsnagPluginVue()]
  })
  bugsnagVue = Bugsnag.getPlugin('vue')
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    const {
      response: { status, data },
    } = err
    if (status === 403 || data.message === 'Unauthenticated.') {

      // if (localStorage.getItem('refreshTokenData')) {
      //   store.dispatch('user/REFRESH_TOKEN')
      // }
      localStorage.removeItem('refreshTokenData')
      localStorage.removeItem('userTokenData')
      location.href = "/"

      // router.push('/')
    }

    return Promise.reject(err)
  }
)

const pinia = createPinia()
const app = createApp(App)

// @ts-ignore
app.config.productionTip = false
// @ts-ignore
app.config.unwrapInjectedRef = true

// Components
app.component('SignLayout', SignLayout)
app.component('ProfileLayout', ProfileLayout)

// App
app.use(store)
app.use(pinia)
app.use(router)
app.use(Maska)
app.use(DisableAutocomplete)
if (!!bugsnagVue) {
  app.use(bugsnagVue)
}

// @ts-ignore
app.use(VueCookies, {
  expireTimes: 10 * 365 * 24 * 60 * 60,
})
app.use(VueScrollTo, { duration: 1500 })
app.use(vClickOutside)
app.mount('#app')
