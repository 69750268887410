import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  modal: boolean
}

const state = (): IState => ({
  modal: false
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_MODAL_POLITIC(state, payload) {
    state.modal = payload
  },
}

const actions: ActionTree<RootState, RootState> = {
  CHANGE_MODAL_POLITIC({ commit, state }) {
    commit('SET_MODAL_POLITIC', !state.modal)
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_MODAL_POLITIC(state) {
    return state.modal
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
