import { GetterTree, MutationTree } from 'vuex'

interface IState {
  systemSelection: string | null
}

const state = (): IState => ({
  systemSelection: null,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_SELECTED_SYSTEM(state, payload) {
    state.systemSelection = payload
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_SELECTED_SYSTEM(state) {
    return state.systemSelection
  },
}
export default {
  namespaced: true,
  mutations,
  state,
  getters,
}
