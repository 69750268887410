import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'users',
    component: () => import('@/views/pages/usersPage.vue'),
    meta: {
      layout: 'sign-layout',
    },
  },
  {
    path: '/:shop',
    name: 'shop',
    component: () => import('@/views/pages/emptyPage.vue'),
    meta: {
      layout: 'empty-layout',
    },
  },
  {
    path: '/users/registration',
    name: 'registration',
    component: () => import('@/views/pages/registrationPage.vue'),
    meta: {
      layout: 'sign-layout',
      guest: true,
    },
  },
  {
    path: '/users/forget',
    name: 'forgetPassword',
    component: () => import('@/views/pages/forgetPasswordPage.vue'),
    meta: {
      layout: 'sign-layout',
      guest: true,
    },
  },
  {
    path: '/users/forget/:id',
    name: 'forgetId',
    component: () => import('@/views/pages/forgetPasswordIdPage.vue'),
    meta: {
      layout: 'sign-layout',
    },
  },
  {
    path: '/table/:id',
    name: 'tableDetail',
    component: () => import('@/views/pages/tableDetail.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/profile/profile.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/inaccessibility',
    name: 'inaccessibility',
    component: () => import('@/views/pages/profile/inaccessibility.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/mailing',
    name: 'mailing',
    component: () => import('@/views/pages/profile/mailing.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/plug',
    name: 'plug',
    component: () => import('@/views/pages/profile/plug.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
      subtitle: 'Заглушить Tapper',
    },
  },
  {
    path: '/profile-email',
    name: 'profileEmail',
    component: () => import('@/views/pages/profile/profileEmail.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/telegram',
    name: 'telegram',
    component: () => import('@/views/pages/profile/telegram.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/order-history',
    name: 'OrderHistory',
    component: () => import('@/views/pages/profile/order-history.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('@/views/pages/profile/menu.vue'),
    meta: {
      layout: 'empty-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/logs',
    name: 'Logs',
    component: () => import('@/views/pages/profile/logs.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/integration',
    name: 'Integration',
    component: () => import('@/views/pages/profile/integration.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/waiter',
    name: 'Waiter',
    component: () => import('@/views/pages/profile/waiter.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/qr',
    name: 'Qr',
    component: () => import('@/views/pages/profile/qr.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/pages/profile/history.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/req',
    name: 'Req',
    component: () => import('@/views/pages/profile/req.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/virtual-card',
    name: 'Virtual Card',
    component: () => import('@/views/pages/profile/virtualCard.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/customization',
    name: 'Customization',
    component: () => import('@/views/pages/profile/customization.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/cloud',
    name: 'Cloud',
    component: () => import('@/views/pages/profile/cloud.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('@/views/pages/profile/analytics.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/stocks',
    name: 'Stocks',
    component: () => import('@/views/pages/profile/stocks.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/tips',
    name: 'Tips',
    component: () => import('@/views/pages/profile/tips.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/waiter-tips',
    name: 'WaiterTips',
    component: () => import('@/views/pages/waiter-tips.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('@/views/pages/profile/statistics.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/fin-statistics',
    name: 'finStatistics',
    component: () => import('@/views/pages/profile/fin-statistics.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
  {
    path: '/tapper-tips',
    name: 'tapperTips',
    component: () => import('@/views/pages/profile/tapper-tips.vue'),
    meta: {
      layout: 'profile-layout',
      requiresAuth: true,
    },
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['user/GET_AUTHENTIFICATED']) {
      next('/')
      return
    }
    next()
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['user/GET_AUTHENTIFICATED']) {
      next()
      return
    }
    next('/users')
  } else {
    next()
  }
})

export default router
