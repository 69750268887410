import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  authentication_status: boolean
}

const state = (): IState => ({
  authentication_status: false,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_STATUS(state, payload) {
    state.authentication_status = payload
  }
}

const actions: ActionTree<RootState, RootState> = {
  CHAANGE_STATUS({ commit }, payload) {
    commit('SET_STATUS', payload)
  }
}

const getters: GetterTree<RootState, RootState> = {
  GET_AUTHENTICATION_STATUS(state) {
    return state.authentication_status
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
