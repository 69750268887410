import axios from 'axios'

const state = () => ({
  transactions: 0,
})

const mutations = {
  SET_TRANSACTIONS(state, payload) {
    state.transactions = payload
  },
}

const actions = {
  async PAY_CLOUD({}, payload) {
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/payment/payorder`,
        payload
      )
      return res.data
    } catch (e) {
      console.log(e)
    }
  },
  async CALCULATE_TRANSACTIONS({ commit }, payload) {
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/p2p/cloudtips/calculate-commission?amount=${payload.amount}&layoutId=${payload.layoutId}`
      )
      return res.data
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  GET_TRANSACTIONS(state) {
    return state.transactions
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
