
import { defineComponent, PropType } from 'vue'
import { Field } from 'vee-validate'
import { defineRule, configure } from 'vee-validate'

configure({
  validateOnInput: true,
})

// Обязательное поле
defineRule('required', (modelValue: string) => {
  if (modelValue && modelValue.trim()) {
    return true
  }
  return 'Поле не может быть пустым!'
})

// Только русские символы
defineRule('rus', (modelValue: string) => {
  const regexp = /^[а-яА-ЯёЁ\s]+$/
  const res = regexp.test(modelValue)
  if (res) {
    return true
  }
  return 'Имя может содержать только буквы А-Я'
})

// Телефон
defineRule('phone', (modelValue: string) => {
  if (modelValue.length === 17) {
    return true
  }
  return 'Некорректный номер телефона'
})

// mail
defineRule('mail', (modelValue: string) => {
  const regexp =
    /^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/gm
  const res = regexp.test(modelValue)
  if (res) {
    return true
  }
  return 'Введите корректный E-mail'
})

// TelegramID
defineRule('telegramId', (modelValue: string) => {
  const regexp = /^[-]\d+$/
  const res = regexp.test(modelValue)
  if (res) {
    return true
  }
  return 'Введите корректный ID'
})

// Минимальное кол-во
defineRule('min', (modelValue: string, args: number[]): string | boolean => {
  if (modelValue.length >= args[0]) {
    return true
  }
  return `Введите минимум ${args[0]} символов`
})

// Максимальное кол-во
defineRule('max', (modelValue: string, args: number[]): string | boolean => {
  if (modelValue && modelValue.length <= args[0]) {
    return true
  }
  return `Введите не более ${args[0]} символов`
})

// Проверка совпадения пароля
defineRule('confirmed', (modelValue: string, [target]: string) => {
  if (modelValue === target) {
    return true
  }
  return 'Пароли не совпадают'
})

// Название заведения
defineRule('name_shop', (modelValue: string) => {
  const regexp = /^(?![- ])[a-zA-Zа-яА-ЯёЁ0-9-\s]+$/
  const res = regexp.test(modelValue)
  if (res) {
    return true
  }
  return 'Введите корректное название без использования символов ! # $ @ % ^ * + = ` ~ \\ | / ? , . < > " \'\ ; : № & _ ( ) [ ] { }'
})

export default defineComponent ({
  name: 'VLandingInput',
  components: {
    Field,
  },
  props: {
    blockPaste: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: true,
    },
    placeholder: {
      type: String as PropType<string>,
      require: true,
      default: '',
    },
    mask: {
      type: String as PropType<string>,
      require: false,
      default: '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: false,
    },
    id: {
      type: String as PropType<string>,
      require: true,
      default: '',
    },
    modelValue: {
      type: String as PropType<string>,
      require: true,
      default: '',
    },
    value: {
      type: String as PropType<string>,
      require: true,
      default: '',
    },
    type: {
      type: String as PropType<string>,
      require: false,
      default: 'text',
    },
    rule: {
      type: String as PropType<string>,
      require: false,
      default: '',
    },
    error: {
      type: String as PropType<string>,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: false,
    },
    emptyFieldError: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: false,
    },
    maxLength: {
      type: String as PropType<string>,
      require: false,
      default: '',
    },
    marginBottom: {
      type: Number as PropType<number>,
      require: false,
      default: 20,
    },
    mobileScreen: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: false,
    },
    errorText: {
      type: Boolean as PropType<boolean>,
      require: false,
      default: true,
    },
  },

  emits: ['update:modelValue'],
  data() {
    return {
      showPassword: false,
    }
  },
  methods: {
    onInput(event: Event): void {
      const target = event.target as HTMLInputElement; // Приведение типа к HTMLInputElement
      this.$emit('update:modelValue', target.value)
    },
    onPaste(evt: ClipboardEvent): void {
      if (this.id === 'confirmation' || this.id === 'password') {
        if (this.blockPaste) {
          evt.preventDefault()
        }
      }
    },
    toggleShowPassword(): void {
      this.showPassword = !this.showPassword
    },
  },
})
