<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M4 5.99999V18M15.99 5.98999H7.99M12 14V18M15 18H9M22 5.99999H2M20 18V5.99999M8 11H16H15.99C16.54 10.99 16.99 11.44 16.99 11.99V12.49C16.99 13.31 16.31 13.99 15.49 13.99C15.48 13.99 15.48 13.99 15.48 13.99H8.48H8.47C7.64 13.98 6.97 13.31 6.97 12.49V11.99C6.96 11.43 7.41 10.98 7.96 10.98L8 11Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
