<template>
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M1.07077 12.2514C1.01761 11.8023 0.991028 11.5773 1.00272 11.3922C1.03115 10.9206 1.21926 10.4722 1.53673 10.1193C1.8542 9.76638 2.28255 9.52944 2.75276 9.44666C2.93776 9.41406 3.16635 9.41406 3.62459 9.41406H14.8595C15.3167 9.41406 15.5453 9.41406 15.7303 9.44561C16.2005 9.52839 16.6288 9.76533 16.9463 10.1183C17.2638 10.4712 17.4519 10.9196 17.4803 11.3911C17.4909 11.5773 17.4654 11.8013 17.4123 12.2514L16.9859 15.8564C16.8189 17.2681 16.1336 18.57 15.0601 19.5149C13.9866 20.4598 12.5997 20.9818 11.1627 20.982H7.3203C5.88331 20.9818 4.49641 20.4598 3.42292 19.5149C2.34944 18.57 1.66413 17.2681 1.49711 15.8564L1.07077 12.2514Z"
      stroke-width="1.5"
    />
    <path
      d="M16.6846 16.7746H18.811C19.2299 16.7746 19.6446 16.693 20.0316 16.5345C20.4186 16.3759 20.7702 16.1435 21.0664 15.8506C21.3626 15.5576 21.5975 15.2098 21.7578 14.8271C21.9181 14.4443 22.0006 14.034 22.0006 13.6197C22.0006 13.2054 21.9181 12.7952 21.7578 12.4124C21.5975 12.0296 21.3626 11.6818 21.0664 11.3889C20.7702 11.0959 20.4186 10.8635 20.0316 10.705C19.6446 10.5464 19.2299 10.4648 18.811 10.4648H17.2162"
      stroke-width="1.5"
    />
    <path
      d="M9.24156 1C9.10191 1.13809 8.99113 1.30203 8.91555 1.48247C8.83997 1.66292 8.80107 1.85632 8.80107 2.05163C8.80107 2.24695 8.83997 2.44035 8.91555 2.62079C8.99113 2.80123 9.10191 2.96518 9.24156 3.10326C9.38121 3.24135 9.49199 3.4053 9.56757 3.58574C9.64315 3.76618 9.68205 3.95958 9.68205 4.15489C9.68205 4.35021 9.64315 4.54361 9.56757 4.72405C9.49199 4.90449 9.38121 5.06844 9.24156 5.20652M3.92553 6.78397L4.04886 6.66198C4.30999 6.40415 4.4682 6.06171 4.49437 5.69768C4.52055 5.33364 4.41294 4.9725 4.19133 4.68071C3.96994 4.38883 3.86246 4.02774 3.88863 3.66377C3.9148 3.29979 4.07287 2.95738 4.3338 2.69944L4.45713 2.57745M14.026 6.78397L14.1493 6.66198C14.4105 6.40415 14.5687 6.06171 14.5948 5.69768C14.621 5.33364 14.5134 4.9725 14.2918 4.68071C14.0704 4.38883 13.9629 4.02774 13.9891 3.66377C14.0153 3.29979 14.1733 2.95738 14.4343 2.69944L14.5576 2.57745"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
