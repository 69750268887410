import { RESTAURANTS } from './../../../restaurants'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  [key: string]: boolean
}

interface IAB {
  name: string
  data: string[]
}

interface IMutationPayload {
  name: string
  status: boolean
}

// state
const state = (): IState => ({
  sommelier: false,
  barista: false,
  barmen: false,
  fromHookahToBarmen: false
})

type RootState = ReturnType<typeof state>

// mutations
const mutations: MutationTree<RootState> = {
  SET_DATA (state, payload: IMutationPayload) {
    state[payload.name] = payload.status
  }
}

// actions
const actions: ActionTree<RootState, RootState> = {
  async CHECK_AB_TEST ({ commit }, currentRest: string) {
    RESTAURANTS.forEach((ab: IAB) => {
      if (ab.data.includes(currentRest)) {
        commit('SET_DATA', { name: ab.name, status: true })
      }
    })
  }
}

// getters
const getters: GetterTree<RootState, RootState> = {}
const copyState = state()
for (const key in copyState) {
  const name = 'AB_' + key.replace(/([A-Z])/g, '_$1').toUpperCase()
  getters[name] = function (state: IState) {
    return state[key]
  }
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters
}
