import axios from 'axios'
import { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { IWaiters, IWaiter } from '@/types/waiter'

interface IState {
  waiters: IWaiters;
  waitersError: boolean;
}

interface IWaitersResponse {
  currentPage: number,
  data: IWaiter,
  limit: number,
  totalItem: number,
  totalPage: number
}

const state = (): IState => ({
  waiters: {
    pageCurrent: 0,
    pages: 0,
    waiters: {}
  },
  waitersError: false,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_WAITERS(state, waiters) {
    state.waiters = waiters
  },
  SET_WAITERS_ERROR(state, status) {
    state.waitersError = status
  },
  // Локальное обновление официанта
  UPDATE_WAITER(state, data) {
    let item

    for (const key in state.waiters?.waiters) {
      if (state.waiters.waiters[key].user_id === data.user_id) {
        item = state.waiters.waiters[key]
      }
    }

    if (item) {
      item.telegram_id = data.telegram_id
      item.telegram_name = data.telegram_nickname
    }
  },
}
// Получение официантов
const actions: ActionTree<RootState, RootState> = {
  // async FETCH_WAITERS({ commit }, payload) {
  //   try {
  //     const respone: AxiosResponse<IWaitersResponse> = await axios.get(
  //       `${process.env.VUE_APP_BASE_URL}/waiter`,
  //       {
  //         params: {
  //           page: payload.page,
  //           count: payload.count,
  //         },
  //         headers: {
  //           Authorization: 'Bearer ' + payload.token,
  //         },
  //       }
  //     )
  //     commit('SET_WAITERS', {
  //       pages: respone.data.countPage,
  //       pageCurrent: respone.data.pageCurrent,
  //       waiters: respone.data.waiter,
  //     })
  //   } catch (e) {
  //     console.log(e)
  //   }
  // },

  async UPDATE_TIPS_LINK(_, payload) {
    const { token, tips_link } = payload
    try {
      const res = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/waiterwaiter/tips-link`,
        { tips_link },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return res
    } catch (e) {
      console.log(e)
    }
  },

  // Обновить фициантов на кассе в админке
  async UPDATE_WAITERS_ADMIN({}, token) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/waiter/refresh-waiters`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },

  // Обновить фициантов на кассе в тех поде
  async UPDATE_WAITERS_SUPORT({}, payload) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/refresh-waiters`,
        {
          shop_id: payload.shop_id
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },

  // Поиск официанта
  async SEARCH_WAITERS({ commit }, payload) {
    try {
      const url = payload.restId
        ? `${process.env.VUE_APP_BASE_URL}/support/waiters/list/${payload.restId}`
        : `${process.env.VUE_APP_BASE_URL}/waiter/list`

      const response: AxiosResponse<IWaitersResponse> = await axios.get(url, {
        params: {
          page: payload.page,
          limit: payload.limit,
          search: payload.search,
          shop_id: payload.restId,
        },
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      })
      commit('SET_WAITERS', {
        pages: response.data.totalPage,
        pageCurrent: response.data.currentPage,
        waiters: response.data.data,
      })
    } catch (e) {
      console.log(e)
    }
  },

  // Удалить аватарку
  async DEL_AVATAR({}, token) {
    try {
      await axios.delete(
        `${process.env.VUE_APP_BASE_URL}/waiter/avatar`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },

  // Загрузить аватарку
  async UPLOAD_AVATAR({}, payload) {
    const request = {
      img: payload.img,
    }
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/waiter/avatar`,
        request,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },

  // Приглашени
  async INVITATION({ commit, dispatch, state }, payload) {
    commit('SET_WAITERS_ERROR', false)
    try {
      const request = {
        id: payload.id.toString(),
        email: payload.email,
      }
      const response: AxiosResponse<{success: boolean}> = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/waiter/invitation`,
        request,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      if (response && response.data.success) {
        dispatch(
          'authentication/CHAANGE_STATUS',
          { status: true, msg: 'Официант успешно приглашен' },
          { root: true }
        )
        const copyWaiters = state.waiters.waiters
        Object.keys(copyWaiters).forEach((i) => {
          if (copyWaiters[i].external_id == payload.id) {
            copyWaiters[i].waiter_status = 'INVITED'
            copyWaiters[i].email = payload.email
          }
        })

        const res = {
          ...state.waiters,
          waiters: copyWaiters,
        }

        commit('SET_WAITERS', res)
      }
    } catch (e) {
      commit('SET_WAITERS_ERROR', true)
    } finally {
      setTimeout(() => {
        dispatch(
          'authentication/CHAANGE_STATUS',
          { status: false, msg: '' },
          { root: true }
        )
      }, 2500)
    }
  },

  // Отмена приглашения
  async UNDOINVITATION({ commit, state }, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/waiter/cancel-invitation`,
        { external_id: payload.external_id },
        { headers: { Authorization: 'Bearer ' + payload.token } }
      )
      const copyWaiters = state.waiters.waiters
      Object.keys(copyWaiters).forEach((i) => {
        if (copyWaiters[i].external_id == payload.external_id) {
          copyWaiters[i].waiter_status = 'NOT INVITED'
          copyWaiters[i].email = ''
        }
      })

      const res = {
        ...state.waiters,
        waiters: copyWaiters,
      }

      commit('SET_WAITERS', res)
    } catch (e) {
      console.log(e)
    }
  },

  // Отвязка email
  async UNLINK_EMAIL({ commit, state }, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/waiter/unlink-email`,
        { external_id: payload.external_id },
        { headers: { Authorization: 'Bearer ' + payload.token } }
      )
      const copyWaiters = state.waiters.waiters
      Object.keys(copyWaiters).forEach((i) => {
        if (copyWaiters[i].external_id == payload.external_id) {
          copyWaiters[i].waiter_status = 'NOT INVITED'
          copyWaiters[i].email = ''
        }
      })

      const res = {
        ...state.waiters,
        waiters: copyWaiters,
      }

      commit('SET_WAITERS', res)
    } catch (e) {
      console.log(e)
    }
  },

  // Сохранение Логина и Имени в Telegram
  async SAVE_TELEGRAM_DATA({ commit }, payload) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/waiter/telegram/${payload.user_id}`,
        {
          telegram_nickname: payload.telegram_nickname,
          telegram_id: payload.telegram_id,
        },
        { headers: { Authorization: 'Bearer ' + payload.token } }
      )

      commit('UPDATE_WAITER', payload)
    } catch (e) {
      console.log(e)
    }
  },

  // Вериикация карты
  async CARD_VERIFICATION({}, token) {
    try {
      const response: AxiosResponse<{success: boolean, result: string}> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/waiter/card-verification`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )

      // window.open(response.data.result, '_blank');
      location.href = response.data.result
    } catch (e) {
      console.log(e)
    }
  },

  async ROLE_CARD_VERIFICATION({}, payload) {
    try {
      const response: AxiosResponse<{success: boolean, result: string}> = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/${payload.role}/card-verification`,
          {
            headers: {
              Authorization: 'Bearer ' + payload.token,
            },
          }
      )
      location.href = response.data.result
    } catch (e) {
      console.log(e)
    }
  },

}

const getters: GetterTree<RootState, RootState> = {
  WAITERS(state) {
    return state.waiters
  },
  WAITERS_ERROR(state) {
    return state.waitersError
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
