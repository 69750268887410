import axios, { AxiosResponse, AxiosError } from 'axios'
import { ActionTree } from 'vuex'

interface IState {
  isLoading: boolean
}

interface IExternalReviewResponse {
  success: boolean
  result: {
    id: number
    shop_id: number
    platform: string
    url: string
  }
}

interface Error {
  message: string[];
  statusCode: number;
}

const state = (): IState => ({
  isLoading: false
})

type RootState = ReturnType<typeof state>

const actions: ActionTree<RootState, RootState> = {
  async FETCH_EXTERNAL_REVIEWS(_, token) {
    try {
      const { data }: AxiosResponse<IExternalReviewResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/external-reviews`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return data.result
    } catch (e) {
      console.log(e)
    }
  },


  async SAVE_EXTERNAL_REVIEWS(_, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/external-reviews`,
        payload.query,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      const error = e as AxiosError<Error>

      console.log('====================================')
      console.log(
        'SAVE_EXTERNAL_REVIEWS_ERROR',
        JSON.stringify(error.response?.data, null, 2)
      )
      console.log('====================================')

      if (error.response?.data.message.includes('The given data was invalid.')) {
        return error.response.data;
      }
    }
  },
}

export default {
  namespaced: true,
  actions,
}
