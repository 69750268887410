import axios, {AxiosResponse} from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  shop_error: string,
  two_entities: boolean,
}

interface IPayload {
  success: boolean
}

interface ITeamItem {
  id: number
  name: string
  roles: string
  shop_id: number
  avatar_link: number
  purpose: string
  card_verification: boolean
  active: boolean
  card_linked: {
    id: number
    token: string
    item_id: number
    item_type: string
    number_card: string
    tips_link: string
  },
  avatar: string
}

interface ITeamResponse {
  success: boolean
  result: {
    kitchener?: ITeamItem | {},
    hookah: ITeamItem | {},
    team: ITeamItem | {},
  }
}

interface IResponseTwoEntities {
  success: boolean
  two_entities: boolean
}

const state = (): IState => ({
  shop_error: '',
  two_entities: false,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_SHOP_ERRORS(state, msg) {
    state.shop_error = msg
  },
  CLEAR_SHOP_ERRORS(state) {
    state.shop_error = ''
  },
  SET_TWO_ENTITIES(state, payload: IResponseTwoEntities) {
    state.two_entities = payload.two_entities
  },
}

const actions: ActionTree<RootState, RootState> = {
  async CHECK_SHOPS({ commit }, payload) {
    commit('CLEAR_SHOP_ERRORS')
    try {
      const query = {
        name: payload,
      }
      const { data }: AxiosResponse<IPayload> = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/shop/examination-name`,
        query
      )
      if (!data.success) {
        commit('SET_SHOP_ERRORS', 'Заведение с таким именем уже существует!')
      }
    } catch (e) {
      console.log(e)
      commit('SET_SHOP_ERRORS', 'Неверное название заведенеия')
    }
  },
  CLEAR_SHOP_ERRORS({ commit }) {
    commit('CLEAR_SHOP_ERRORS')
  },
  async GET_SHOP_TEAM(_, token) {
    try {
      const { data }: AxiosResponse<ITeamResponse> = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/tips/team`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
      )
      if( Array.isArray(data.result) ) {
        data.result.hookah = {}
        data.result.kitchener = {}
        data.result.team = {}
      }

      return data.result
    } catch (e) {
      console.log(e)
    }
  },
  async PUT_SHOP_TEAM(_, payload) {
    try {
      const res = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/team`,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + payload.token,
            },
          }
      )
      return res
    } catch (e) {
      console.log(e)
    }
  },
  async CHECK_TWO_ENTITIES({ commit }, payload) {
    try {
      const result: AxiosResponse<IResponseTwoEntities> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/shop/${payload.id}/two-entities`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      console.log('result', result.data)
      commit('SET_TWO_ENTITIES', result.data)
      return result.data
    } catch (e) {
      console.log(e)
    }
  },
  async PUT_TWO_ENTITIES(_, payload) {
    try {
      const res = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/shop/${payload.id}/two-entities`,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return res
    } catch (e) {
      console.log(e)
    }
  },
}

const getters: GetterTree<RootState, RootState> = {
  SHOP_ERROR(state) {
    return state.shop_error
  },
  GET_TWO_ENTITIES(state) {
    return state.two_entities
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

