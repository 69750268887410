import axios from 'axios'
import { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { IRKeeperData, IPlatformsData, IResult, IAcquiringSupport, IFetchAcquiringInfoResponse } from '@/types/support'
import { IFetchAcquiring } from '@/types/acquiring'

interface IState {
  rkeeperData: IRKeeperData | null
  error: boolean
  platformsData: IPlatformsData | null
  result: IResult | null
  acquiringSupport: IAcquiringSupport | null
  acquiringSupportAlert: string | null
  resultVersion: IResultVersion | null
}
interface IFetchRkeeperResponse {
  success: boolean
  result: IRKeeperData
}

interface IFetchPplatformResponse {
  data?: {
    id: number
    name: string
  }
  success: boolean
  platforms?: {
    id: number
    name: string
  }[]
  errorText?: string
}

interface IShopActiveResponse {
  success: boolean
  result: IResult
}

interface IResultVersion {
  accountingSystemVersion: string
  accountingSystemVersionUpdatedAt: string
  moduleVersion: string
  error: string
  activeShop: boolean
}

interface ISupportCheckoutVersion {
  success: boolean
  result: IResultVersion
}

const state = (): IState => ({
  rkeeperData: null,
  error: false,
  platformsData: null,
  result: null,
  acquiringSupport: null,
  acquiringSupportAlert: null,
  resultVersion: null
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_RKEEPER_DATA(state, data) {
    state.rkeeperData = data
  },
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_PLATFORMS_DATA(state, data) {
    state.platformsData = data
  },
  SET_RESULT_DATA(state, data) {
    state.result = data
  },
  SET_ACQUIRIG_SUPPORT(state, data) {
    state.acquiringSupport = data
  },
  SET_ACQUIRIG_ALERT(state, msg) {
    state.acquiringSupportAlert = msg
  },
  SET_CHECKOUT_VERSION(state, msg) {
    state.resultVersion = msg
  },
}

const actions: ActionTree<RootState, RootState> = {
  async SETUP_ACQUIRIG_SUPPORT({ commit }, payload) {
    commit('SET_ACQUIRIG_ALERT', null)
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/acquiring`,
        {
          acquiring_id: payload.id_shop,
          currency_type: payload.currency_type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
    }
  },
  async FETCH_ACQUIRIG_SUPPORT({ commit }, payload) {
    commit('SET_ACQUIRIG_ALERT', null)
    try {
      const { data: currentData }: AxiosResponse<IFetchAcquiring> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/acquiring-settings/shop/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const { data: generalData }: AxiosResponse<IFetchAcquiringInfoResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/acquiring-settings/info`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const taxationsModified =
        generalData.data && generalData.data.taxation_systems
          ? generalData.data?.taxation_systems
          : []

      await commit('SET_ACQUIRIG_SUPPORT', {
        acquirings: generalData.data?.acquirings.map((e) => ({
          ...e,
          name: e.code,
        })),
        currencyTypes: generalData.data?.currencies,
        data: currentData.data,
        taxations: taxationsModified,
        tax_all: generalData.data?.taxs,
      })
      if (currentData.errorText) {
        commit('SET_ACQUIRIG_ALERT', currentData.errorText)
        return false
      } else {
        return true
      }
    } catch (e) {
      commit('SET_ACQUIRIG_ALERT', 'Заведение не подключено')
      console.log(e)
      return false
    }
  },
  async FETCH_DATA_RKEEPER({ commit }, payload) {
    commit('SET_ERROR', false)
    try {
      const { data: response }: AxiosResponse<IFetchRkeeperResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (!response) {
        commit('SET_ERROR', true)
      }
      if (response.success) {
        commit('SET_RKEEPER_DATA', response.result)
      }
    } catch (e) {
      console.log(e)
      commit('SET_ERROR', true)
    }
  },
  async SAVE_DATA_RKEEPER({ commit }, payload) {
    const data = {
      ...payload.data,
    }
    commit('SET_ERROR', false)
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      commit('SET_ERROR', true)
      console.log(e)
    }
  },
  async FETCH_DATA_IIKO({ commit }, payload) {
    commit('SET_ERROR', false)
    try {
      const { data: response }: AxiosResponse<IFetchRkeeperResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/iiko/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (!response) {
        commit('SET_ERROR', true)
      }
      if (response.success) {
        commit('SET_RKEEPER_DATA', response.result)
      }
    } catch (e) {
      commit('SET_ERROR', true)
      console.log(e)
    }
  },
  async SAVE_DATA_IIKO({ commit }, payload) {
    const data = {
      ...payload.data,
    }
    commit('SET_ERROR', false)
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/iiko`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      commit('SET_ERROR', true)
      console.log(e)
    }
  },
  // Получение информации по платформе
  async FETCH_PLATFORM({ commit }, payload) {
    try {
      const { data: response }: AxiosResponse<IFetchPplatformResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/platform?id=${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const data: IPlatformsData = {
        errorText: '',
        data: null,
        platforms: response.platforms ?? [],
      }
      if (response.success) {
        data.data = response.data ?? null
      } else {
        data.errorText = response.errorText ?? ''
      }

      commit('SET_PLATFORMS_DATA', data)
    } catch (e) {
      console.log(e)
    }
  },
  // Сохранить платформу
  async SAVE_PLATFORM({ commit, state }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/platform?id=${payload.id}`,
        { platform_id: payload.platform_id },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      const data = {
        ...state.platformsData,
        errorText: null,
      }
      commit('SET_PLATFORMS_DATA', data)
      return response.data
    } catch (e) {
      console.log(e)
    }
  },

  async SHOP_ACTIVE({ commit }, payload) {
    try {
      const { data: response }: AxiosResponse<IShopActiveResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/shop/active/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_RESULT_DATA', response.result)
    } catch (e) {
      console.log(e)
    }
  },
  async SHOP_ACTIVE_CONSERVATION({  }, payload) {
    const data = {
      ...payload.data,
    }

    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/shop/active/${data.id}`,
        data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log('e', e)
    }
  },

  // Получение версии кассы
  async FETCH_CHECKOUT_VERSION({ commit }, payload ) {
    try {
      const { data: response }: AxiosResponse<ISupportCheckoutVersion> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/shop-get-version/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_CHECKOUT_VERSION', response.result)
    } catch (e) {
      console.log(e)
      commit('SET_CHECKOUT_VERSION', 'Версия не известна')
    }
  }

}

const getters: GetterTree<RootState, RootState> = {
  RKEEPER_DATA(state) {
    return state.rkeeperData
  },
  ERROR(state) {
    return state.error
  },
  GET_PLATFORMS_DATA(state) {
    return state.platformsData
  },
  GET_ACTIVITY_STATUS_DATA(state) {
    return state.result
  },
  GET_ACQUIRIG_SUPPORT(state) {
    return state.acquiringSupport
  },
  GET_ACQUIRIG_ALERT(state) {
    return state.acquiringSupportAlert
  },
  GET_CHECKOUT_VERSION(state) {
    return state.resultVersion
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
