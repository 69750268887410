<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M12 21.5V8.5M20 8.5H4C3.448 8.5 3 8.948 3 9.5V11.5C3 12.052 3.448 12.5 4 12.5H20C20.552 12.5 21 12.052 21 11.5V9.5C21 8.948 20.552 8.5 20 8.5Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.8197 4.1195C11.6047 5.3835 12.0987 8.4995 12.0987 8.4995C12.9207 8.4995 14.6177 8.2335 15.6957 7.1115C16.4897 6.2855 16.4897 4.9455 15.6957 4.1195C14.9017 3.2935 13.6147 3.2935 12.8207 4.1195M8.30371 7.1115C9.38171 8.2335 11.0787 8.4995 11.9007 8.4995"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.901 8.4995C11.901 8.4995 12.395 5.3835 11.18 4.1195M8.304 7.1115C7.51 6.2855 7.51 4.9455 8.304 4.1195C9.098 3.2935 10.385 3.2935 11.179 4.1195M19 12.4995V20.4995C19 21.0515 18.552 21.4995 18 21.4995H6C5.448 21.4995 5 21.0515 5 20.4995V12.4995"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
