import axios from 'axios'
import { ActionTree } from 'vuex'

interface IState {
  isLoading: boolean
}

const state = (): IState => ({
  isLoading: false
})

type RootState = ReturnType<typeof state>

const actions: ActionTree<RootState, RootState> = {
  async SAVE_PLUG(_, payload) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/stub/set-stub`,
        {
          ids: payload.ids,
          type: payload.type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  actions,
}
