import axios, { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface ILoaderRespose {
  success: boolean,
  result: {
    gif: string
    size: string
    code_color: string
  }
}

interface IState {
  loader: {
    gif: string
    size: string
    code_color: string
  },
}

const state = (): IState => ({
  loader: {
    gif: '',
    size: '',
    code_color: '',
  },
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_LOADER(state, payload) {
    state.loader = payload
  },
}


const actions: ActionTree<RootState, RootState> = {
  async SAVE_LOADER({ commit }, payload) {
    await axios
      .post(
        `${process.env.VUE_APP_BASE_URL}/shop/loader/${payload.merchantId}`,
        payload.data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      .then(() => {
        commit('SET_LOADER', payload.data)
      })
  },
  async FETCH_LOADER({ commit }, payload) {
    const { data }: AxiosResponse<ILoaderRespose> = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/shop/loader/${payload.merchantId}`,
      {
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
      }
    )

    const { gif, size, code_color } = data.result
    commit('SET_LOADER', { gif, size, code_color })
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_LOADER: (state) => state.loader,
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
