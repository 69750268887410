import axios from 'axios'
import convertPhoneToFull from '@/utils/convertPhoneToFull'

const state = () => ({
  userTokenData: localStorage.getItem('userTokenData') || null,
  refreshTokenData: localStorage.getItem('refreshTokenData') || null,
  user_error: '',
  error_forget_id: false,
  registration_success: false,
  recovery_password_success: false,
  userData: {},
  waiterCloud: {},
})

const mutations = {
  SET_USER_ERRORS(state, msg) {
    state.user_error = msg
  },
  CLEAR_USER_ERRORS(state) {
    state.user_error = ''
  },
  SET_ERROR_FORGET_ID(state, payload) {
    state.error_forget_id = payload
  },
  SET_REGISTRATION_SUCCESS(state, payload) {
    state.registration_success = payload
  },
  SET_RECOVERYPASSWORD_SUCCESS(state, payload) {
    state.recovery_password_success = payload
  },
  SET_USER_TOKEN(state, payload) {
    state.userTokenData = payload
  },
  SET_REFRESH_TOKEN(state, payload) {
    state.refreshTokenData = payload
  },
  SET_USER_DATA(state, payload) {
    state.userData = payload
  },
  SET_CLOUD_DATA(state, payload) {
    state.waiterCloud = payload
  },
}

const actions = {
  // Проверка на существующий Email
  async CHECK_EMAIL({ commit }, payload) {
    commit('CLEAR_USER_ERRORS')
    try {
      const query = {
        email: payload,
      }
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/users/examination-email`,
        query
      )
      if (response.data.success) {
        commit('SET_USER_ERRORS', 'Этот E-mail уже зарегистрирован')
      }
    } catch (e) {
      console.log(e)
      commit('SET_USER_ERRORS', 'Ошибка сервера')
    }
  },

  // Авторизация
  async SIGNIN({ commit }, payload) {
    commit('CLEAR_USER_ERRORS')
    try {
      const query = {
        email: payload.email,
        password: payload.password,
      }
      await axios
        .post(`${process.env.VUE_APP_BASE_URL}/users/login`, query)
        .then((res) => {
          localStorage.setItem('userTokenData', res.data.result.access_token)
          localStorage.setItem(
            'refreshTokenData',
            res.data.result.refresh_token
          )
          commit('SET_USER_TOKEN', res.data.result.access_token)
          commit('SET_REFRESH_TOKEN', res.data.result.refresh_token)
        })
    } catch (e) {
      console.log(e)
      commit('SET_USER_ERRORS', 'Неверный E-mail или пароль')
    }
  },

  // Регистрация
  async SIGNUP({ commit }, payload) {
    try {
      const query = {
        email: payload.email,
        name: payload.name,
        password: payload.password,
        phone: payload.phone,
        name_shop: payload.name_shop,
        city: payload.city
      }
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/users/registration`,
        query
      )
      if (response.data.success) {
        const response2 = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/users/login`,
          { email: query.email, password: query.password }
        )
        localStorage.setItem(
          'userTokenData',
          response2.data.result.access_token
        )
        commit('SET_USER_TOKEN', response2.data.result.access_token)
        commit('SET_REGISTRATION_SUCCESS', true)
      }
    } catch (e) {
      console.log(e)
    }
  },

  // Восстановление пароля
  async FORGET_PASSWORD({ commit }, payload) {
    commit('CLEAR_USER_ERRORS')
    try {
      const query = {
        email: payload,
      }
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/users/examination-email`,
        query
      )
      if (response.data.success) {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/users/password-recovery-link`,
          query
        )
      } else {
        commit('SET_USER_ERRORS', 'Такого емейл не существует!')
      }
    } catch (e) {
      console.log(e)
    }
  },

  // Проверка ссылки для восстановления пароля
  async CHECK_ID_FORGET_PASSWORD({ commit }, payload) {
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/users/password-recovery-link/${payload}`
    )
    commit('SET_ERROR_FORGET_ID', response.data.success)
  },

  // Восстановление пароля
  async PASSWORD_RECOVERY({ commit }, payload) {
    commit('SET_RECOVERYPASSWORD_SUCCESS', false)
    try {
      const query = {
        code: payload.code,
        password: payload.password,
      }
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/users/change-password-recovery`,
        query
      )
      if (response.data.success) {
        commit('SET_RECOVERYPASSWORD_SUCCESS', true)
      }
    } catch (e) {
      console.log(e)
    }
  },

  // Данные пользователя
  async GET_USER_DATA({ commit, state }, token) {
    if (state.userTokenData !== null) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/users/profile`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        )

        if (response.data.success) {
          commit('SET_USER_DATA', response.data.result)
        }
      } catch (e) {
        console.log(e)
      }
    }
  },

  // Обновить данные пользователя
  async UPDATE_USER_DATA({ commit }, payload) {
    commit('CLEAR_USER_ERRORS')
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/users/profile`,
        payload.userData,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (response.data.success) {
        const response2 = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/users/profile`,
          {
            headers: {
              Authorization: 'Bearer ' + payload.token,
            },
          }
        )
        if (response2.data.success) {
          commit('SET_USER_DATA', response2.data.result)
        }
      } else {
        commit('SET_USER_ERRORS', 'Ошибка сохранения')
      }
    } catch (e) {
      console.log(e)
      commit('SET_USER_ERRORS', 'Ошибка сохранения')
    }
  },

  // Выход из профиля
  async LOGOUT({ commit, state }) {
    await axios.post(`${process.env.VUE_APP_BASE_URL}/users/logout`, {}, {
      headers: {
        Authorization: 'Bearer ' + state.userTokenData,
      },
    })
    localStorage.removeItem('userTokenData')
    commit('SET_USER_DATA', {})
    commit('SET_USER_TOKEN', null)
  },

  // Refresh Token
  async REFRESH_TOKEN({ commit, state }) {
    try {
      await axios
        .post(`${process.env.VUE_APP_BASE_URL}/users/refresh_token`, {
          token: state.refreshTokenData,
        })
        .then((res) => {
          localStorage.setItem('userTokenData', res.data.result.access_token)
          localStorage.setItem('refreshTokenData', res.data.result.refresh_token)
          commit('SET_USER_TOKEN', res.data.result.access_token)
          commit('SET_REFRESH_TOKEN', res.data.result.refresh_token)
          document.location.reload()
        })
    } catch (err) {
      console.log(err)
    }
  },

  // Очистка ошибок
  ACTION_CLEAR_USER_ERRORS({ commit }) {
    commit('CLEAR_USER_ERRORS')
  },

  CLOSE_REGISTRATION_ACCESS({ commit }) {
    commit('SET_REGISTRATION_SUCCESS', false)
  },

  // Изменение аватара
  async TIPS_AVATAR({}, payload) {
    const request = {
      img: payload.img,
    }
    try {
      await axios.put(
          `${process.env.VUE_APP_BASE_URL}/${payload.role}/avatar`,
          request,
          {
            headers: {
              Authorization: 'Bearer ' + payload.token,
            },
          }
      )
    } catch (e) {
      console.log(e)
    }
  },

  async DEL_TIPS_AVATAR({}, payload) {
    try {
      const response = await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/${payload.role}/avatar`,
          {
            headers: {
              Authorization: 'Bearer ' + payload.token,
            },
          }
      )
    } catch (e) {
      console.log(e)
    }
  },

}

const getters = {
  USER_ERROR(state) {
    return state.user_error
  },
  USER_DATA(state) {
    return state.userData
  },
  CLOUD_DATA(state) {
    return state.waiterCloud
  },
  GET_REGISTRATION_SUCCESS(state) {
    return state.registration_success
  },
  GET_AUTHENTIFICATED(state) {
    return state.userTokenData
  },
  GET_ERROR_FORGET_ID(state) {
    return state.error_forget_id
  },
  GET_RECOVERYPASSWORD_SUCCESS(state) {
    return state.recovery_password_success
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
