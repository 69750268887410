import axios from 'axios'
import { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { IRequisites, ISaveReqPayload } from '@/types/requisites'

interface IState {
  requisites: IRequisites
}

interface IFetchReq {
  success: boolean
  result: IRequisites
}

const state = (): IState => ({
  requisites: {
    id: null,
    shops_id: null,
    name_organization: '',
    inn: '',
    phone: '',
    created_at: '',
    updated_at: ''
  },
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<IState> = {
  SET_REQUISITES(state, requisites) {
    state.requisites = requisites
  },
}

const actions: ActionTree<RootState, RootState> = {
  async FETCH_REQUISITES({ commit }, token: string) {
    try {
      const respone: AxiosResponse<IFetchReq> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/shop/requisites`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_REQUISITES', respone.data.result)
    } catch (e) {
      console.log(e)
    }
  },

  async SAVE_REQUISITES({}, payload: ISaveReqPayload) {
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/shop/requisites`,
        payload.data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters: GetterTree<RootState, RootState> = {
  REQUISITES(state: IState): IRequisites {
    return state.requisites
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
