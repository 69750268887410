<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M10.75 13.25H8.5C8.10218 13.25 7.72064 13.092 7.43934 12.8107C7.15804 12.5294 7 12.1478 7 11.75V8.5C7 8.10218 7.15804 7.72064 7.43934 7.43934C7.72064 7.15804 8.10218 7 8.5 7H11.75C12.1478 7 12.5294 7.15804 12.8107 7.43934C13.092 7.72064 13.25 8.10218 13.25 8.5V10.75"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.5 10.75H12.25C11.4216 10.75 10.75 11.4216 10.75 12.25V15.5C10.75 16.3284 11.4216 17 12.25 17H15.5C16.3284 17 17 16.3284 17 15.5V12.25C17 11.4216 16.3284 10.75 15.5 10.75Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 10V16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21H8C6.67392 21 5.40215 20.4732 4.46447 19.5355C3.52678 18.5979 3 17.3261 3 16V8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3H14"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 5H17"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19 7V3"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
