const renameDublicateArrayElement = (arr: string[]): string[] => {
  let filterArray: string[] = []
  const duplicates = []

  const tempArray = [...arr].sort()

  for (let i = 0; i < tempArray.length; i++) {
    if (tempArray[i + 1] === tempArray[i]) {
      duplicates.push(tempArray[i])
    }
  }

  filterArray = [...arr]

  duplicates.map((i) => {
    let counter = 0
    const el: string[] = []
    filterArray.forEach((j) => {
      if (i === j) {
        counter++
        el.push(j + counter)
      } else {
        el.push(j)
      }
    })
    filterArray = el
  })

  return filterArray
}

export default renameDublicateArrayElement
