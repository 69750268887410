import axios from 'axios'
import { ActionTree, MutationTree } from 'vuex'

interface IState {
  tips_type: string
}

const state = (): IState => ({
  tips_type: '',
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_TIPS_TYPE(state, status) {
    state.tips_type = status
  },
}

const actions: ActionTree<RootState, RootState> = {
  async CHANGE_TIPS_TYPE(_, payload) {
    const { token, id, type } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/tips/`,
        {
          shop_id: id,
          tips_type: type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  mutations,
  actions
}
