import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  preloader: boolean;
  text: string;
}

const state = (): IState => ({
  preloader: true,
  text: '',
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_PRELOADER(state, payload) {
    state.preloader = payload
  },
  SET_TEXT(state, payload) {
    state.text = payload
  },
}

const actions: ActionTree<RootState, RootState> = {
  TOGGLE_PRELOADER({ commit }, payload) {
    commit('SET_PRELOADER', payload)
  },
  SET_TEXT_PRELOADER({ commit }, payload) {
    commit('SET_TEXT', payload)
  },
}

const getters: GetterTree<RootState, RootState> = {
  PRELOADER(state) {
    return state.preloader
  },
  PRELOADER_TEXT(state) {
    return state.text
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
