<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M13.5 20.5V20.094C13.5033 18.9389 14.4389 18.0033 15.594 18H18.906C20.0611 18.0033 20.9967 18.9389 21 20.094V20.5C21 20.7761 20.7761 21 20.5 21H14C13.7239 21 13.5 20.7761 13.5 20.5ZM15.25 13.492C15.2544 12.3874 16.1534 11.4956 17.258 11.5C18.3625 11.5044 19.2544 12.4034 19.25 13.508C19.2456 14.6094 18.3514 15.5 17.25 15.5C16.1454 15.5 15.25 14.6046 15.25 13.5C15.25 13.4974 15.25 13.4947 15.25 13.492Z"
    />
    <path
      d="M9 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V8M7 7.062H12M7 10.562H12M7 14.562H12M13.5 20.5V20.094C13.5033 18.9389 14.4389 18.0033 15.594 18H18.906C20.0611 18.0033 20.9967 18.9389 21 20.094V20.5C21 20.7761 20.7761 21 20.5 21H14C13.7239 21 13.5 20.7761 13.5 20.5ZM15.25 13.492C15.2544 12.3874 16.1534 11.4956 17.258 11.5C18.3625 11.5044 19.2544 12.4034 19.25 13.508C19.2456 14.6094 18.3514 15.5 17.25 15.5C16.1454 15.5 15.25 14.6046 15.25 13.5C15.25 13.4974 15.25 13.4947 15.25 13.492Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
