import axios, { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  idLicense: string | number | null
}

interface IResponse {
  success: boolean
}

interface IFetchLicenseTypeResponse extends IResponse {
  result: {
    license_type: string
  }
}

interface IFetchLicenseResponse extends IResponse {
  result: {
    date_expiration_license: string
    id_license: string
  }
}

interface IUpdateLicenseResponse {
  date_expiration_license: string
  id_license: string
}

interface IEndDateResponse extends IResponse {
  'date-expiration': string
}

const state = (): IState => ({
  idLicense: null,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<IState> = {
  SET_LICNSE(state, data) {
    state.idLicense = data
  },
}

const actions: ActionTree<RootState, RootState> = {
  async FETCH_LICENSE_TYPE(_, payload) {
    try {
      const { data }: AxiosResponse<IFetchLicenseTypeResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}/license/type`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return data.result.license_type
    } catch (e) {
      console.log(e)
    }
  },

  async FETCH_END_DATA_LICENSE(_, payload) {
    try {
      const { data }: AxiosResponse<IEndDateResponse> = await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}/license/date-expiration`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return data['date-expiration']
    } catch (e) {
      console.log(e)
    }
  },

  async SAVE_LICENSE(_, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}/license/type`,
        {
          license_type: payload.type,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },

  async FETCH_LICENSE({ commit }, payload) {
    try {
      const { data: dataResponse }: AxiosResponse<IFetchLicenseResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/r-keeper/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      let data = {
        date_expiration_license: '',
        id_license: '',
      }

      if (dataResponse.success) {
        data = {
          date_expiration_license: dataResponse.result.date_expiration_license,
          id_license: dataResponse.result.id_license,
        }
      }

      commit('SET_LICNSE', data)
      return true
    } catch (e) {
      console.log(e)
      const data = {
        date_expiration_license: '',
        id_license: '',
      }

      commit('SET_LICNSE', data)
      return false
    }
  },

  async UPDATE_ID_LICENSE({ commit }, payload) {
    try {
      const { data: dataResponse }: AxiosResponse<IUpdateLicenseResponse> = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/rkeeperupdatelicense`,
        { id: payload.id },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const data = {
        date_expiration_license: dataResponse.date_expiration_license,
        id_license: dataResponse.id_license,
      }

      commit('SET_LICNSE', data)
    } catch (e) {
      console.log(e)
    }
  },

  async CLEAR_CACHE_TABLES({}, payload) {
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/support/update-cache-table`,
        { id: payload.id },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return res.data
    } catch (e) {
      console.log(e)
    }
  },

  async CLEAR_CACHE_ALL(_, token) {
    try {
      await axios.get(`${process.env.VUE_APP_BASE_URL}/support/cache-clear`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      return true
    } catch (e) {
      console.log(e)
    }
  },
}

const getters: GetterTree<RootState, RootState> = {
  LICENSE_DATA(state) {
    return state.idLicense
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
