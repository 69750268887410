import axios, { AxiosResponse, AxiosError } from 'axios'
import renameDublicateArrayElements from '@/utils/renameDublicateArrayElement'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { ICategory, IProduct } from '@/types/menu'

interface IState {
  categories: ICategory[]
  adminMenuTest: []
  visibleMenu: number
  updateMenuStatus: boolean
}

interface IFetchMenuResponse {
  success: boolean
  result: {
    status: string
    visible: boolean
    categories: ICategory[]
    percent_loaded: number
  }
}

interface IMenuUpdateResponse {
  success: boolean
}

interface ILabel {
  name: string
  status: boolean
}

interface ILabels {
  [key: string]: ILabel
}

const state = (): IState => ({
  categories: [],
  adminMenuTest: [],
  visibleMenu: 1,
  updateMenuStatus: false,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_CATEGORIES(state, categories) {
    state.categories = categories
  },
  SET_ADMIN_MENU_TEST(state, adminMenuTest) {
    state.adminMenuTest = adminMenuTest
  },
  SET_VISIBLE_MENU(state, status) {
    state.visibleMenu = status
  },
  SET_UPDATE_MENU_STATUS(state, status) {
    state.updateMenuStatus = status
  },
}

const actions: ActionTree<RootState, RootState> = {
  // Редактирование категори
  async EDIT_CATEGORY({ commit, state }, payload) {
    const copyCategories = state.categories
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/update-category/${payload.id}`,
        {
          internal_name: payload.internal_name,
          list_position: payload.list_position,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      copyCategories.forEach((i) => {
        if (payload.id === i.id) {
          i.internal_name = payload.internal_name
        }
        if (i.internal_name === '-') {
          i.internal_name = null
        }
      })
      commit('SET_CATEGORIES', copyCategories)
    } catch (e) {
      console.log(e)
    }
  },

  // Очистить категории
  CLEAR_CATEGORIES({ commit }) {
    commit('SET_CATEGORIES', [])
  },

  // Получить все меню
  async FETCH_MENU({ commit }, payload) {
    try {
      const { data }: AxiosResponse<IFetchMenuResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/menu/get-menu`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      if (data.success) {
        if (data.result.status === 'loading') {
          return data.result.percent_loaded
            ? data.result.percent_loaded
            : 0
        } else {
          const categories = data.result.categories
          const status = data.result.visible
          const resultTemp: ICategory[] = []
          const result: ICategory[] = []

          categories.forEach((i) => {
            if (i.products.length) {
              resultTemp.push(i)
            }
          })

          let orderCategoryCount = 0
          let orderProductCount = 0

          resultTemp.forEach((i) => {
            const products: IProduct[] = []
            if (!i.hidden) orderCategoryCount++
            orderProductCount = 0
            i.products.forEach((j) => {
              if (!j.hidden) orderProductCount++
              products.push({
                ...j,
                order: !j.hidden ? orderProductCount : null,
                nameСheckout: j.name,
                ourName:
                  j.internal_name !== 'null' && j.internal_name !== '-' ? j.internal_name : '',
                editContent: false,
                hideOnOff: true,
                category: i.name,
              })
            })
            result.push({
              ...i,
              internal_name:
                i.internal_name !== 'null' && i.internal_name !== '-' ? i.internal_name : '',
              order: !i.hidden ? orderCategoryCount : null,
              products: [...products],
            })
          })

          const categoryArrayNames = result.map((i) => i.name)

          const filteredArray = renameDublicateArrayElements(categoryArrayNames)

          result.forEach((i, index) => {
            i.name = filteredArray[index]
            i.products.forEach((product) => {
              const updatedLabels: ILabels = {
                vegan: {
                  name: 'Веган',
                  status: false
                },
                spicy: {
                  name: 'Острое',
                  status: false
                },
                gluten_free: {
                  name: 'Безглютеновое',
                  status: false
                },
                dietary: {
                  name: 'Диетическое',
                  status: false
                },
                hit: {
                  name: 'Хит',
                  status: false
                },
                child: {
                  name: 'Детское меню',
                  status: false
                }
              }

              for (const label in product.labels) {
                updatedLabels[label] = {
                  ...updatedLabels[label],
                  status: product.labels[label]
                }
              }

              product.labels = updatedLabels
            });
          })

          if (result.length) {
            commit('SET_UPDATE_MENU_STATUS', false)
            localStorage.removeItem('timer')
          }

          commit('SET_CATEGORIES', result)
          commit('SET_VISIBLE_MENU', status)
          return 100
        }
      }
    } catch (e) {
      console.log(e)
      return 'error'
    }
  },

  // Сортировка категорй
  async CATEGORY_SORT({ dispatch }, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/update-category/${payload.id}`,
        {
          internal_name: payload.ourName ? payload.ourName : '-',
          list_position: payload.listPosition,
        },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      dispatch('CLEAR_CATEGORIES')
      await dispatch('FETCH_MENU', { token: payload.token })
    } catch (e) {
      console.log(e)
    }
  },

  // Переключить видимость категории
  async CATEGORY_VISIBLE({ commit, state }, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/category-visible/${payload.id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      let orderCategoryCount = 0
      const copyCategories = [...state.categories]
      copyCategories.forEach((i) => {
        if (i.id === payload.id) {
          i.hidden = i.hidden ? 0 : 1
        }
        if (!i.hidden) {
          orderCategoryCount++
          i.order = orderCategoryCount
        } else {
          i.order = null
        }
      })

      commit('SET_CATEGORIES', copyCategories)
    } catch (e) {
      console.log(e)
    }
  },

  // Переключить видимость продукта
  async PRODUCT_VISIBLE({ commit, state }, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/product-visible/${payload.id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      let orderProductCount = 0
      const copyCategories = [...state.categories]
      copyCategories.forEach((i) => {
        orderProductCount = 0
        i.products.forEach((j) => {
          if (j.id === payload.id) {
            j.hidden = j.hidden ? 0 : 1
          }
          if (!j.hidden) {
            orderProductCount++
            j.order = orderProductCount
          } else {
            j.order = null
          }
        })
      })

      commit('SET_CATEGORIES', copyCategories)
    } catch (e) {
      console.log(e)
    }
  },

  // Сортировка продукта
  async PRODUCT_SORT({ dispatch }, payload) {
    const newPayload = { ...payload }
    delete newPayload.product_picture
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/menu/product-update/${payload.id}`,
        newPayload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      dispatch('CLEAR_CATEGORIES')
      await dispatch('FETCH_MENU', { token: payload.token })
    } catch (e) {
      console.log(e)
    }
  },

  // Обновление продукта
  async PRODUCT_UPDATE({ commit, state }, payload) {
    try {
      const formData = new FormData()
      if (payload.product_picture) {
        formData.append('product_picture', payload.product_picture)

        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/menu/product-update/${payload.id}`,
          formData,
          {
            headers: {
              Authorization: 'Bearer ' + payload.token,
            },
          }
        )
      }

      delete payload.product_picture

      const { data } = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/menu/product-update/${payload.id}`,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      const copyCategories = [...state.categories]
      await copyCategories.forEach((i) => {
        i.products.forEach((j) => {
          if (j.id === payload.id) {
            j.ourName =
              data.result.internal_name !== 'null' && data.result.internal_name

            if (payload.delete_picture) {
              j.product_picture = null
            }
          }
        })
      })
      commit('SET_CATEGORIES', copyCategories)
      return data.result.product_picture
    } catch (e) {
      const error = e as AxiosError<Error>
      console.log('====================================')
      console.log('PRODUCT_UPDATE__ERROR', JSON.stringify(error.response?.data, null, 2))
      console.log('====================================')
      return Promise.reject(error.response?.data)
    }
  },

  // Видимость меню
  async MENU_VISIBLE({ commit, state }, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/menu/menu-visible/${payload.domen}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_VISIBLE_MENU', state.visibleMenu === 1 ? 0 : 1)
    } catch (e) {
      console.log(e)
    }
  },

  // Обновление меню
  async MENU_UPDATE({ commit }, payload) {
    commit('SET_UPDATE_MENU_STATUS', true)
    try {
      const { data }: AxiosResponse<IMenuUpdateResponse> = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/menu/menu-update/${payload.shop_id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (data.success) {
        return true
      }
    } catch (e) {
      console.log(e)
    }
  },

  // Обновление превью

}

const getters: GetterTree<RootState, RootState> = {
  GET_CATEGORIES(state) {
    return state.categories
  },
  GET_ADMIN_MENU_TEST(state) {
    return state.adminMenuTest
  },
  GET_VISIBLE_MENU(state) {
    return state.visibleMenu
  },
  GET_UPDATE_MENU_STATUS(state) {
    return state.updateMenuStatus
  }
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
