<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6438 19.9074L20.0998 5.18741C20.3968 4.38541 19.6158 3.60541 18.8148 3.90241L4.0898 9.36241C3.1688 9.70441 3.2408 11.0304 4.1938 11.2694L11.0278 12.9864L12.7348 19.8024C12.9748 20.7564 14.3018 20.8294 14.6438 19.9074V19.9074Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.8703 4.13086L11.0303 12.9909"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
