import axios from 'axios'
import { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { ITelegramItem } from '@/types/telegramTypes'

interface IState {
  groups: ITelegramItem[]
}

interface IFetchGroupsResponse {
  success: boolean,
  result: ITelegramItem[]
}
interface IPayloadMailing {
  req: {
    message: string
    role: string
  }
  token: string
}
interface IResponse {
  id?: string
  token?: string
  notifications?: string
  telegramId?: number
  messenger?: string
}

interface ICreateGroupResponse {
  success: boolean
  result: {
    id: number
    name: string
    users_id: number
    type: string
    telegram_id: number
    notifications: {
      waiter_call: number
      rating: number
      payment: number
    }
  }
}

const state = (): IState => ({
  groups: [],
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<IState> = {
  SET_GROUPS(state, groups) {
    state.groups = groups
  },
}

const actions: ActionTree<RootState, RootState> = {
  async CREATE_TELEGRAM(_, payload: IResponse) {
    const {messenger, token} = payload
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/telegram`,
        messenger,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async UPDATE_TELEGRAM(_, payload: IResponse) {
    const {messenger, token} = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/telegram`,
        messenger,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async MAILING_TELEGRAM(_, payload: IPayloadMailing) {
    const req = {
      message: payload.req.message,
      role: payload.req.role,
    }
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/telegram/mailing-list/send`,
        req,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log('catch', e)
    }
  },
  async FETCH_GROUPS({ commit }, token: string) {
    try {
      const { data }: AxiosResponse<IFetchGroupsResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/telegram/groups`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_GROUPS', data.result)
    } catch (e) {
      console.log(e)
    }
  },
  async DELETE_GROUP(_, payload: IResponse) {
    const { id, token } = payload
    try {
      await axios.delete(
        `${process.env.VUE_APP_BASE_URL}/telegram/group/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async CREATE_GROUP(_, payload: IResponse) {
    const { telegramId, token } = payload
    try {
      const { data }: AxiosResponse<ICreateGroupResponse> = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/telegram/group/create`,
        {
          telegram_id: telegramId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return data.result.id
    } catch (e) {
      console.log(e)
    }
  },
  async UPDATE_GROUP(_, payload: IResponse) {
    const { telegramId, token, id } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/telegram/group/update`,
        {
          telegram_id: telegramId,
          id,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async NOTIFICATIONS(_, payload: IResponse) {
    const { token, id, notifications } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/telegram/${id}/notifications`,
        {
          notifications,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async DELETE_WAITER(_, payload: IResponse) {
    const { id, token } = payload
    try {
      await axios.delete(
        `${process.env.VUE_APP_BASE_URL}/telegram/contact/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_GROUPS(state) {
    return state.groups
  },
}

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
