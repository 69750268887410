import axios, { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { ITables, IRestaurant, IRestaurantsPages, ITable } from '@/types/restaurant'

interface IState {
  tables: ITables[],
  restaurants: IRestaurant[],
  restaurants_pages: IRestaurantsPages | null,
  selectRest: IRestaurant | null,
  selectedTable: ITable | null,
}

interface IFetchRestaurantsResponse {
  success: boolean
  result: IRestaurant[]
}

interface IShowMoreRestResponse {
  success: boolean
  result: {
    data: IRestaurant[]
    current_page: number,
    last_page: number
  }
}

interface IFetchSelectedTableResponse {
  success: boolean
  table: ITable
}

interface IFetchTablesReq {
  page: number,
  count: number,
  search?: string,
  range_from: string,
  range_to: string,
  not_show: string,
  shop_id?: number
}

const state = (): IState => ({
  tables: [],
  restaurants: [],
  restaurants_pages: null,
  selectRest: null,
  selectedTable: null,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<IState> = {
  SET_TABLES(state, tables) {
    state.tables = tables
  },
  SET_RESTAURANTS(state, restaurants) {
    state.restaurants = restaurants
  },
  SET_RESTAURANTS_PAGES(state, restaurants_pages) {
    state.restaurants_pages = restaurants_pages
  },
  SET_SELECT_REST(state, data) {
    state.selectRest = data
  },
  SET_CURRENT_TABLE(state, payload) {
    state.selectedTable = payload
  },
}
const actions: ActionTree<RootState, RootState> = {
  async FETCH_INFO_RESTAURANT(_, payload) {
    const { token, id } = payload
    try {
      await axios.get(
        `${process.env.VUE_APP_BASE_URL}/restaurant/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  SELECT_REST({ commit }, rest) {
    commit('SET_SELECT_REST', rest)
  },
  async FETCH_RESTAURANTS({ commit }, payload) {
    const { token, search } = payload
    try {
      const { data }: AxiosResponse<IFetchRestaurantsResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/shops?search=${search}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )

      let restArr = []
      restArr = [...data.result]

      commit('SET_RESTAURANTS', restArr)
    } catch (e) {
      console.log(e)
    }
  },

  CHANGE_TIPS({ commit }, rest) {
    commit('SET_SELECT_REST', rest)
  },

  CHANGE_PLATFORM_REST({ commit, state }, payload) {
    const copyRests = state.restaurants
    copyRests.forEach((i) => {
      if (i.id == payload.id) {
        i.platforms_id = payload.platformsId
        i.external_id = payload.externalId
      }
    })
    commit('SET_RESTAURANTS', copyRests)
  },

  async SHOW_MORE_RESTAURANTS({ commit, state }, { token, page }) {
    try {
      const { data }: AxiosResponse<IShowMoreRestResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/shops?page=${page}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      const newArray = [...state.restaurants, ...data.result.data]
      const newData = {
        current_page: data.result.current_page,
        last_page: data.result.last_page,
      }
      commit('SET_RESTAURANTS_PAGES', newData)
      commit('SET_RESTAURANTS', newArray)
    } catch (e) {
      console.log(e)
    }
  },

  async FETCH_SELECTED_TABLE({ commit }, payload) {

    const { table_id, shop_id, token } = payload
    try {
      const { data }: AxiosResponse<IFetchSelectedTableResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/handbook/table/${table_id}${shop_id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )


      const selectedTable = Array.isArray(data?.table)
          ? data?.table[0]
          : data?.table

      commit('SET_CURRENT_TABLE', selectedTable)
    } catch (e) {
      console.log(e)
    }
  },

  async FETCH_TABLES({ commit }, payload) {
    const { page, range_from, range_to, shop_id } = payload

    const req: IFetchTablesReq = {
      page,
      count: 21,
      range_from,
      range_to,
      not_show: '1',
    }
    if (shop_id) {
      req.shop_id = shop_id
    }
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/handbook/tables`,
        {
          params: req,
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      const res = response ? response.data : []
      commit('SET_TABLES', res)
    } catch (e) {
      console.log(e)
    }
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_TABLES(state) {
    return state.tables
  },
  GET_RESTAURANTS(state) {
    return state.restaurants
  },
  GET_RESTAURANTS_PAGES(state) {
    return state.restaurants_pages
  },
  GET_SELECT_REST(state) {
    return state.selectRest
  },
  GET_SELECTED_TABLE(state) {
    return state.selectedTable
  }
}
export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
}
