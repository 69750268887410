import axios, { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IWifiInfo {
  id: number
  shop_id: number
  network: string
  password: string
}

interface IState {
  info_wifi: IWifiInfo | null
}

interface IGetWifiResponse {
  success: boolean
  result: IWifiInfo
}

interface IWifiUpdatePayload {
  network: string
  password: string
  token: string
  active: boolean
}

const state = (): IState => ({
  info_wifi: null
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_INFO_WIFI(state, list) {
    state.info_wifi = list
  },
}

const actions: ActionTree<RootState, RootState> = {
  // Получение данных Wi-Fi
  async FETCH_INFO_WIFI({ commit }, token: string) {
    try {
      const { data }: AxiosResponse<IGetWifiResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/wifi`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_INFO_WIFI', data.result)
      return data.result
    } catch (e) {
      console.log(e)
    }
  },

  // Добавление/Обновление wifi для ресторана
  async UPDATING_WIFI_INFORMATION(_, payload: IWifiUpdatePayload) {
    try {
      const query = {
        network: payload.network,
        password: payload.password,
        active: payload.active,
      }
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/admin-panel/shop/wifi`,
        query,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_INFO_WIFI(state) {
    return state.info_wifi
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

