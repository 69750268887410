import axios from 'axios'

const state = () => ({
  virtualCardInfo: null,
  errorPhoneCode: null,
  balance: 0,
  percents: 0,
  error: null,
})

const mutations = {
  SET_VIRTUAL_CARD_INFO(state, info) {
    state.virtualCardInfo = info
  },
  SET_BALANCE(state, sum) {
    state.balance = sum
  },
  SET_ERROR_PHONE_CODE(state, error) {
    state.errorPhoneCode = error
  },
  SET_ERROR(state, msg) {
    state.error = msg
  },
  SET_PERCENTS(state, percents) {
    state.percents = percents
  },
}

const actions = {
  async FETCH_VIRTUAL_CARD_INFO({ commit }, token) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/p2p/getwaiterinfo`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      const status = !response.data.success
        ? 'notReadyet'
        : response.data.success && !response.data.data.virtual_card_phone
        ? 'inactive'
        : 'active'
      commit('SET_VIRTUAL_CARD_INFO', { ...response.data, status: status })
    } catch (e) {
      console.log(e)
    }
  },
  async REGISTER_VIRTUAL_CARD_INFO({ commit }, token) {
    try {
      const respone = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/p2p/register`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async VALID_PHONE_VIRTUAL_CARD_INFO({ commit }, payload) {
    commit('SET_ERROR', null)
    commit('SET_ERROR_PHONE_CODE', false)
    let phone = payload.phone
    phone = phone.replace(/[^0-9]/g, '')
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/p2p/checkphoneservice`,
        { phone: phone },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (!response.data.success) {
        commit('SET_ERROR_PHONE_CODE', true)
        commit('SET_ERROR', response.data.message)
        setTimeout(() => {
          commit('SET_ERROR', null)
        }, 5000)
      }

      return response.data
    } catch (e) {
      console.log(e)
    }
  },
  async SET_PHONE_SERVICE({ commit }, payload) {
    commit('SET_ERROR', null)
    let phone = payload.phone
    phone = phone.replace(/[^0-9]/g, '')
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/p2p/setphoneservice`,
        { phone: phone, smscode: payload.code },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (!response.data.success) {
        commit('SET_ERROR', response.data.message)
        setTimeout(() => {
          commit('SET_ERROR', null)
        }, 5000)
      }

      return response.data.success
    } catch (e) {
      console.log(e)
    }
  },

  // Обновить статус
  async UPDATE_STATUS({ commit }, token) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/p2p/userinfo`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return response.data.data.active === '5' ? true : false
    } catch (e) {
      console.log(e)
    }
  },

  // Изменение данных пользователя
  async MODIFY({ commit }, payload) {
    commit('SET_ERROR', null)
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/p2p/modify`,
        payload.data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (!response.data.success) {
        commit('SET_ERROR', response.data.message)
        setTimeout(() => {
          commit('SET_ERROR', null)
        }, 5000)
      }

      return response.data.success
    } catch (e) {
      console.log(e)
    }
  },

  // Привязка карты
  async CARDENROLL_VIRTUAL_CARD({}, token) {
    try {
      const respone = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/p2p/cardenroll`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      location = respone.data
    } catch (e) {
      console.log(e)
    }
  },

  // Получение баланса
  async GET_BALANCE_VIRTUAL_CARD({ commit }, token) {
    try {
      const respone = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/p2p/getbalance`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      const balance = respone.data.available_balance
        ? parseInt(respone.data.available_balance) / 100
        : 0
      commit('SET_BALANCE', balance)
    } catch (e) {
      console.log(e)
    }
  },

  // Расчет процентов
  async PAYOUTFEE_VIRTUAL_CARD({ commit }, payload) {
    commit('SET_PERCENTS', 0)
    if (parseInt(payload.amount) > 30) {
      try {
        const respone = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/p2p/payoutfee`,
          { amount: parseInt(payload.amount) },
          {
            headers: {
              Authorization: 'Bearer ' + payload.token,
            },
          }
        )
        commit('SET_PERCENTS', respone.data.toFixed(2))
      } catch (e) {
        console.log(e)
      }
    }
  },

  // Вывод средств
  async PAYOUT_VIRTUAL_CARD({}, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/p2p/payout`,
        { amount: parseInt(payload.amount) },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      location.href = response.data
    } catch (e) {
      console.log(e)
    }
  },
}

const getters = {
  VIRTUAL_CARD_INFO(state) {
    return state.virtualCardInfo
  },
  VIRTUAL_CARD_BALANCE(state) {
    return state.balance
  },
  ERROR_PHONE_CODE(state) {
    return state.errorPhoneCode
  },
  ERROR(state) {
    return state.error
  },
  PERCENTS(state) {
    return state.percents
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
