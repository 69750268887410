<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico"
  >
    <path
      d="M1 5H20"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 7V3C20 1.895 19.105 1 18 1H3C1.895 1 1 1.895 1 3V12C1 13.105 1.895 14 3 14H13.5"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.999 9C21 7.895 19.432 7 17.5 7C15.568 7 14.001 7.896 14 9V15C14 16.104 15.566 17 17.499 17C19.432 17 20.999 16.105 20.999 15V9Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.999 12C20.999 13.105 19.432 14 17.499 14C15.566 14 14 13.104 14 12M14 9C14 10.105 15.567 11 17.5 11C19.433 11 21 10.105 21 9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
