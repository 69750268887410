<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="vProfileMenu__ico svgFill"
  >
    <path
      d="M8.02578 18.633C7.01178 18.633 6.09678 18.468 5.28078 18.138C4.47078 17.808 3.78078 17.346 3.21078 16.752C2.64078 16.152 2.20278 15.45 1.89678 14.646C1.59678 13.836 1.44678 12.954 1.44678 12C1.44678 10.704 1.71378 9.558 2.24778 8.562C2.78178 7.56 3.52278 6.777 4.47078 6.213C5.41878 5.649 6.52278 5.367 7.78278 5.367C8.20878 5.367 8.62278 5.397 9.02478 5.457C9.42678 5.517 9.80778 5.598 10.1678 5.7C10.5278 5.802 10.8608 5.913 11.1668 6.033C11.4728 6.153 11.7368 6.276 11.9588 6.402L11.8148 7.968C11.3468 7.692 10.8908 7.47 10.4468 7.302C10.0088 7.128 9.57678 7.002 9.15078 6.924C8.73078 6.846 8.31978 6.807 7.91778 6.807C6.99378 6.807 6.17178 7.017 5.45178 7.437C4.73178 7.857 4.16478 8.457 3.75078 9.237C3.34278 10.011 3.13878 10.935 3.13878 12.009C3.13878 12.723 3.24378 13.395 3.45378 14.025C3.66978 14.655 3.98778 15.21 4.40778 15.69C4.83378 16.17 5.35878 16.548 5.98278 16.824C6.61278 17.094 7.34478 17.229 8.17878 17.229C8.48478 17.229 8.79078 17.211 9.09678 17.175C9.40278 17.133 9.70578 17.07 10.0058 16.986C10.3058 16.902 10.5998 16.791 10.8878 16.653C11.1818 16.509 11.4698 16.341 11.7518 16.149L11.8778 17.697C11.6258 17.859 11.3468 18 11.0408 18.12C10.7408 18.24 10.4228 18.339 10.0868 18.417C9.75678 18.489 9.41778 18.543 9.06978 18.579C8.72178 18.615 8.37378 18.633 8.02578 18.633Z"
    />
    <path
      d="M14.534 18.435C14.534 17.721 14.534 17.034 14.534 16.374C14.534 15.714 14.534 14.988 14.534 14.196V9.894C14.534 9.084 14.534 8.346 14.534 7.68C14.534 7.008 14.534 6.315 14.534 5.601H16.163C16.163 6.315 16.163 7.008 16.163 7.68C16.163 8.346 16.163 9.084 16.163 9.894V13.944C16.163 14.736 16.163 15.462 16.163 16.122C16.163 16.782 16.163 17.469 16.163 18.183L15.551 16.986H17.909C18.593 16.986 19.196 16.986 19.718 16.986C20.246 16.986 20.732 16.986 21.176 16.986C21.626 16.986 22.085 16.986 22.553 16.986V18.435H14.534Z"
    />
    <circle
      cx="21"
      cy="3"
      r="3"
      fill="#EC4E4E"
    />
  </svg>
</template>
