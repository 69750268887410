import axios from 'axios'
import { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { IDetails, IAcquiring, ISaveAcquiringPayload, IFetchAcquiring } from '@/types/acquiring'

interface IState {
  details: IDetails | null
  acquiring: IAcquiring | null
  alert: null | string
  iikoId: null | boolean
}

interface IFetchAcqiuring {
  id: number
  token: string
}

const state = (): IState => ({
  details: null,
  acquiring: null,
  alert: null,
  iikoId: null,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_DETAILS(state, payload: IDetails) {
    state.details = payload
  },

  SET_ACQUIRIG(state, payload: IAcquiring) {
    state.acquiring = payload
  },
  SET_ALERT(state, msg: string) {
    state.alert = msg
  },
  SET_IIKO_ID(state, id) {
    state.iikoId = id
  },
}

const actions: ActionTree<RootState, RootState> = {
  async SAVE_ACQUIRIG({ commit }, payload: ISaveAcquiringPayload) {
    commit('SET_ALERT', null)
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/acquiring-settings/`,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_ACQUIRIG({ commit }, payload: IFetchAcqiuring) {
    commit('SET_ALERT', null)
    try {
      const response: AxiosResponse<IFetchAcquiring> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/acquiring-settings/shop/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      if (response.data.message) {
        commit('SET_ALERT', response.data.message)
      }
      commit('SET_ACQUIRIG', response.data.data)
    } catch (e) {
      console.log(e)
      commit('SET_ALERT', 'Все плохо :/ ')
    }
  },
  async GET_ID_IIKO(_, payload) {
    try {
      const res: AxiosResponse<{ success: boolean, result: string }> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/iiko/get-id/${payload.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      return res.data.success
    } catch (e) {
      console.log(e)
    }
  },
  async GETTING_DETAILS({ commit }, payload: {id: number, token: string}) {
    const { id, token } = payload
    try {
      const response: AxiosResponse<{ success: boolean, result: IDetails }> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/shop/${id}/requisites`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_DETAILS', response.data.result)
    } catch (e) {
      console.log(e)
    }
  },
  async SAVING_DETAILS_INSTITUTION({  }, payload) {
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/shop/${payload.id}/requisites`,
        payload,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_DETAILS(state) {
    return state.details
  },
  GET_ACQUIRIG(state) {
    return state.acquiring
  },
  GET_ALERT(state) {
    return state.alert
  },
  GET_IIKO_ID(state) {
    return state.iikoId
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
