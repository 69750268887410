import {ActionTree, GetterTree, MutationTree} from "vuex"
import axios, {AxiosResponse} from "axios"
import { getEmployeeName } from '@/utils/getEmployeeName'

const state = (): IState => ({
  employeeList: [],
  employeeRoles: [],
  employeeCertainRole: [],
})

interface IState {
  employeeRoles: IResultEmployeeRoles[]
  employeeList: IResultEmployeeList[]
  employeeCertainRole: IResultEmployeeCertainRole[]
}

interface IResultEmployeeList {
  id: number
  name: string
  role: string
}

interface IEmployeeList {
  success: boolean
  result: IResultEmployeeList
}

interface IResultEmployeeRoles {
  id: number
  role: string
  description: string
  name: string | null
}

interface IRolesList {
  success: boolean
  result: IResultEmployeeRoles[]
}

interface IResultEmployeeCertainRole {
  id: number
  name: string
  employee_role_id: number
}

interface IEmployeeCertainRole {
  success: boolean
  result: IResultEmployeeCertainRole
}

type RootState = ReturnType<typeof state>

const mutations: MutationTree<IState> = {
  SET_EMPLOYEE_LIST(state, employeeList) {
    state.employeeList = employeeList
  },

  SET_EMPLOYEE_ROLES(state, employeeRoles) {
    state.employeeRoles = employeeRoles
  },

  SET_EMPLOYEE_CERTAIN_ROLE(state, employeeCertainRole) {
    state.employeeCertainRole = employeeCertainRole
  }
}

const actions: ActionTree<RootState, RootState> = {

  // Получение списка сотрудников и ролей для заведения
  async FETCH_EMPLOYEE_LIST({ commit }, payload ) {
    try {
      const { data }: AxiosResponse<IEmployeeList> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/employee/shop/${payload.shop_id}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      commit('SET_EMPLOYEE_LIST', data.result)
    } catch (e) {
      console.log(e)
    }
  },

  // Получение списка ролей сотрудников
  async FETCH_EMPLOYEE_ROLES({ commit }, payload ) {
    try {
      const { data }: AxiosResponse<IRolesList> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/employee/role/names`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
      const modifyResult = data.result.map((item: IResultEmployeeRoles) => {
        const name = item.name ?? ''
        return {
          ...item,
          name: item.name ? name.charAt(0).toUpperCase() + name.slice(1) : getEmployeeName(item.role)
        }
      })
      commit('SET_EMPLOYEE_ROLES', modifyResult)
    } catch (e) {
      console.log(e)
    }
  },

  // Список сотрудников определённой роли
  async FETCH_EMPLOYEE_CERTAIN_ROLE({ commit }, payload ) {
    try {
      const { data }: AxiosResponse<IEmployeeCertainRole> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/employee/role/${payload.roleId}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )

      commit('SET_EMPLOYEE_CERTAIN_ROLE', data.result)
    } catch (e) {
      console.log(e)
    }
  },

  // Привязка сотрудника, роли и заведения
  async SAVE_EMPLOYEE_ROLE({}, payload )   {
    try {
      await axios.post(
        `${process.env.VUE_APP_BASE_URL}/employee/shop/${payload.shop_id}`,
        payload.data,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  }
}

const getters: GetterTree<RootState, RootState> = {
  GET_EMPLOYEE_LIST(state) {
    return state.employeeList
  },

  GET_EMPLOYEE_ROLES(state) {
    return state.employeeRoles
  },

  GET_EMPLOYEE_CERTAIN_ROLE(state) {
    return state.employeeCertainRole
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations
}
