import axios, { AxiosResponse } from 'axios'
import { ActionTree } from 'vuex'

interface IState {
  isLoading: boolean
}

interface IResponse {
  success: boolean
  result: {
    loyalty_system: string
  }
}

const state = (): IState => ({
  isLoading: false
})

type RootState = ReturnType<typeof state>

const actions: ActionTree<RootState, RootState> = {
  async FETCH_LOYALTY(_, payload) {
    const { token, id } = payload
    try {
      const { data }: AxiosResponse<IResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/restaurant/${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return data.success
        ? data.result.loyalty_system
        : data.success
    } catch (e) {
      console.log(e)
    }
  },
  async SAVE_LOYALTY(_, payload) {
    const { token, id, loyalty } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/restaurant/change-loyalty-system/${id}`,
        { loyalty_system: loyalty },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  actions,
}

