import axios from 'axios'
import { AxiosResponse } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface ICustomizationResponse {
  success: boolean,
  result?: ICustomization
}

interface ICustomization {
  id: number
  name: string
  code: string
  main_color: string | null
  stars_color: string | null
  logo_tips_link: string | null
}

interface IState {
  customization_list: ICustomization[]
  customization: ICustomization | null
}

interface IFetchCustomixationResponse {
  success: boolean,
  result: ICustomization
}

const state = (): IState => ({
  customization_list: [],
  customization: null,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_CUSTOMIZATION_LIST(state, list) {
    state.customization_list = list
  },
  SET_CUSTOMIZATION(state, type) {
    state.customization = type
  },
}

const actions: ActionTree<RootState, RootState> = {
  async FETCH_CUSTOMIZATION_LIST({ commit }, token: string) {
    try {
      const { data }: AxiosResponse<IFetchCustomixationResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/support/customization/`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      commit('SET_CUSTOMIZATION_LIST', data.result)
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_CUSTOMIZATION_BY_CODE({ commit }, payload) {
    const { code } = payload
    try {
      const { data }: AxiosResponse<ICustomizationResponse> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/shop/customization?code=${code}`
      )
      commit(
        'SET_CUSTOMIZATION',
        data.result ? data.result.code : 'TAPPER'
      )
    } catch (e) {
      console.log(e)
    }
  },
  async UPDATE_CUSTOMIZATION(_, payload) {
    const { token, shopId, customizationId } = payload
    try {
      await axios.put(
        `${process.env.VUE_APP_BASE_URL}/support/shop/set-customization`,
        {
          shop_id: shopId,
          customization_id: customizationId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
    } catch (e) {
      console.log(e)
    }
  }
}

const getters: GetterTree<RootState, RootState> = {
  GET_CUSTOMIZATION_LIST(state) {
    return state.customization_list
  },
  GET_CUSTOMIZATION(state) {
    return state.customization
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
