import axios, { AxiosResponse } from 'axios'
import { ActionTree } from 'vuex'

interface IState {
  isLoading: boolean
}

const state = (): IState => ({
  isLoading: false
})

type RootState = ReturnType<typeof state>


const actions: ActionTree<RootState, RootState> = {
  async EXPORT_STATISTIC(_, payload) {
    try {
      const { data }: AxiosResponse<Blob> = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/statistic/export-day-statistic`,
        { date: payload.date },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', payload.date + '.xlsx')
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      console.log(e)
    }
  },

  async EXPORT_BALANCE(_, payload) {
    try {
      const { data }: AxiosResponse<Blob> = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/statistic/waiter-balance-sheet`,
        { date: payload.date },
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', payload.date + '.xlsx')
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      console.log(e)
    }
  },

  async EXPORT_STATISTIC_PERIOD(_, payload) {
    try {
      const { data }: AxiosResponse<Blob> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/statistic/export-statistics-for-period?from=${payload.from}&before=${payload.before}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )

      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `statistics-for-period?from=${payload.from}&before=${payload.before}` +
          '.xlsx'
      )
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      console.log('e', e)
    }
  },


  async EXPORT_CONVERSION_STATISTICS(_, payload) {
    try {
      const { data }: AxiosResponse<Blob> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/statistic/export-conversion-statistics-for-period?from=${payload.from}&before=${payload.before}`,
        {
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )

      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `conversion-statistics-for-period?from=${payload.from}&before=${payload.before}` +
          '.xlsx'
      )
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      console.log('e', e)
    }
  },

  async EXPORT_TABLE_STATISTICS(_, payload) {
    const req = {
        date_from: payload.date_from,
        date_to: payload.date_to,
        shop_ids: payload.shop_ids
    }
    try {
      const { data }: AxiosResponse<Blob> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/statistic/export/cash-payment`,
        {
          params: req,
          headers: {
            Authorization: 'Bearer ' + payload.token,
          },
          responseType: 'blob',
        }
      )

      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', payload.date_from + ' - ' + payload.date_to + '.xlsx')
      document.body.appendChild(link)
      link.click()

    } catch (e) {
      console.log(e)
    }
  },
}

export default {
  namespaced: true,
  actions
}
