import axios, { AxiosResponse, AxiosError } from 'axios'
import { GetterTree, ActionTree, MutationTree } from 'vuex'

interface IState {
  politicsError: boolean
}

interface Error {
  message: string[];
  statusCode: number;
}

interface IResult {
  code: string
  document: string
}

interface IPayload {
  success: boolean,
  result: IResult[]
}


const state = (): IState => ({
  politicsError: false,
})

type RootState = ReturnType<typeof state>

const mutations: MutationTree<RootState> = {
  SET_POLITICS_ERRORS(state, payload) {
    state.politicsError = payload
  },
}

const actions: ActionTree<RootState, RootState> = {
  async FETCH_NOT_FAMILIRIZATION_DOCUMENTS(_, token) {
    try {
      const { data }: AxiosResponse<IPayload> = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/legal-document/list-not-familiarization-document`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return data.result
    } catch (e) {
      const error = e as AxiosError<Error>

      console.log('====================================')
      console.log(
        'FETCH_NOT_FAMILIRIZATION_DOCUMENTS_ERROR',
        JSON.stringify(error.response?.data, null, 2)
      )
      console.log('====================================')
    }
  },

  async CONFIRM_NOT_FAMILIRIZATION_DOCUMENTS(_, payload) {
    const { token, code } = payload
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/legal-document/mark-confirmed-document`,
        { document_code: code },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return res.data
    } catch (e) {
      const error = e as AxiosError<Error>

      console.log('====================================')
      console.log(
        'CONFIRM_NOT_FAMILIRIZATION_DOCUMENTS_ERROR',
        JSON.stringify(error.response?.data, null, 2)
      )
      console.log('====================================')
    }
  },

  TOGGLE_POLITICS_ERROR({ commit }, payload) {
    commit('SET_POLITICS_ERRORS', payload)
  },
}

const getters: GetterTree<RootState, RootState> = {
  GET_POLITICS_ERROR(state) {
    return state.politicsError
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
